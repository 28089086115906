import React from 'react';
import { Link } from 'react-router-dom';
import { FaCity, FaCogs, FaWifi, FaNetworkWired } from 'react-icons/fa';
import { BuildingOfficeIcon } from '@heroicons/react/24/solid';

const IoTAndSmartCities = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="relative h-screen bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1519340333755-56e9c1d63e15?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&ixid=MnwzNjUyOXwwfDF8fHx8fHx8fHwxNjExNTMxMzI5&ixlib=rb-1.2.1&q=80&w=1080)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-blue-600 opacity-60"></div>
        <div className="relative z-10 text-white text-center flex flex-col justify-center items-center h-full backdrop-blur-md">
          <h1 className="text-6xl font-bold mb-4 animate-zoomIn">IoT & Smart Cities</h1>
          <p className="text-xl mb-6 max-w-2xl mx-auto">
            Connecting cities with intelligent IoT solutions to create smarter, sustainable urban environments.
          </p>
          <Link to="/contact" className="bg-teal-600 px-8 py-3 rounded-full text-white hover:bg-teal-700 transition duration-300 shadow-lg">
            Get in Touch
          </Link>
        </div>
      </header>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 px-4">
          <div className="flex justify-center items-center h-full">
            <BuildingOfficeIcon 
              className="text-teal-600 transform hover:scale-105 transition-transform duration-500" 
              style={{ width: '200px', height: '200px' }}
            />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-4xl font-bold mb-6 text-gray-800">Why Choose IoT for Smart Cities?</h2>
            <div className="space-y-8">
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-teal-600 text-white rounded-full shadow-lg">
                  <FaCity size={24} />
                </div>
                <p className="text-lg font-medium">Smart Urban Development</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-teal-600 text-white rounded-full shadow-lg">
                  <FaNetworkWired size={24} />
                </div>
                <p className="text-lg font-medium">IoT-Powered Infrastructure</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-teal-600 text-white rounded-full shadow-lg">
                  <FaWifi size={24} />
                </div>
                <p className="text-lg font-medium">Seamless Connectivity</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-teal-600 text-white rounded-full shadow-lg">
                  <FaCogs size={24} />
                </div>
                <p className="text-lg font-medium">Automated City Services</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="py-20 bg-white">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold text-gray-800">Our Expertise</h2>
          <p className="text-lg text-gray-500 mt-4">We specialize in integrating IoT solutions to enhance urban living and sustainability.</p>
        </div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-4">
          <div className="p-12 bg-gradient-to-b from-teal-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaCity size={60} className="text-teal-600 mb-6" />
            <h3 className="text-2xl font-semibold">Smart City Planning</h3>
            <p className="text-gray-600 mt-4">We create scalable smart city solutions for urban development, improving quality of life.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-blue-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaNetworkWired size={60} className="text-blue-600 mb-6" />
            <h3 className="text-2xl font-semibold">IoT Infrastructure</h3>
            <p className="text-gray-600 mt-4">We integrate IoT systems that connect and optimize city services and public infrastructure.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-yellow-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaWifi size={60} className="text-yellow-600 mb-6" />
            <h3 className="text-2xl font-semibold">Smart Connectivity</h3>
            <p className="text-gray-600 mt-4">Our solutions ensure seamless and secure connectivity across smart cities, enabling real-time communication.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <footer className="bg-teal-600 py-16 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Let’s Transform Cities with IoT</h2>
        <p className="text-lg mb-8">Contact us today to discuss your IoT and smart city projects.</p>
        <Link to="/contact" className="bg-white text-teal-600 px-8 py-3 rounded-full hover:bg-gray-100 transition duration-300 shadow-lg">
          Contact Us
        </Link>
      </footer>
    </div>
  );
};

export default IoTAndSmartCities;
