import React from 'react';

const About = () => {
  return (
    <div className="sm:flex items-center max-w-screen-xl mx-auto">
      <div className="sm:w-1/2 p-6 sm:p-10">
        <div className="image object-center text-center">
          <img 
            src="https://i.imgur.com/WbQnbas.png" 
            className="w-full h-auto max-w-xs mx-auto" 
            alt="Our team working on AI solutions"  // Update alt text here
          />
        </div>
      </div>
      <div className="sm:w-1/2 p-6 sm:p-10">
        <div className="text">
          <span className="text-gray-500 border-b-2 border-indigo-600 uppercase">About us</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
            About <span className="text-indigo-600">Our Company</span>
          </h2>
          <p className="text-gray-700">
            We are a UAE based AI company focused on delivering innovative AI solutions to empower businesses. We specialize in machine learning, deep learning, and advanced AI technologies like Retrieval-Augmented Generation and knowledge database systems. Our mission is to provide scalable, efficient solutions that drive business growth and efficiency across industries. We build AI tools that solve real-world problems and deliver measurable impact. Whether you need a dedicated engineer or an entire team, we can provide the right talent to meet your specific needs. 
          </p>        
        </div>
      </div>
    </div>
  );
};

export default About;
