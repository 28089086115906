import React from 'react';
import { FaWhatsapp, FaLocationArrow, FaEnvelope, FaLinkedin, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4 md:px-0 space-y-12 md:space-y-0 md:flex md:justify-between md:items-center">

        {/* Left Section: Contact Info & Social Media */}
        <div className="flex flex-col md:w-1/2 space-y-8 md:space-y-6 ml-4">
          <h3 className="text-xl font-bold mb-4 text-indigo-400">Get in Touch</h3>

          {/* Contact Info */}
          <div className="space-y-4">
            {/* Email */}
            <div className="flex items-center space-x-3">
              <FaEnvelope className="text-indigo-400 text-lg" />
              <span className="text-sm md:text-base">support@quantumgulf.org</span>
            </div>

            {/* Location */}
            <div className="flex items-center space-x-3">
              <FaLocationArrow className="text-indigo-400 text-lg" />
              <span className="text-sm md:text-base">
                Business Centre, Sharjah Publishing City, Sharjah, UAE
              </span>
            </div>

            {/* Phone Number */}
            <div className="flex items-center space-x-3">
              <FaWhatsapp className="text-green-400 text-lg" />
              <span className="text-sm md:text-base">+971581258479</span>
            </div>
          </div>

          {/* Social Media Links */}
          <div className="flex space-x-4 mt-6">
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-2xl hover:text-indigo-400">
              <FaLinkedin />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook" className="text-2xl hover:text-indigo-400">
              <FaFacebook />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-2xl hover:text-indigo-400">
              <FaInstagram />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="text-2xl hover:text-indigo-400">
              <FaTwitter />
            </a>
          </div>
        </div>

        {/* Right Section: Blog */}
        <div className="md:w-1/2 text-center md:text-right mr-4">
          <h3 className="text-xl font-bold mb-4 text-indigo-400">Our Blog</h3>
          <p className="text-sm md:text-base leading-relaxed text-gray-400">
            Dive deep into the latest trends and insights in technology and AI.
          </p>
          <Link to="/blog" className="block mt-4 text-indigo-400 hover:text-white underline">
            Visit Our Blog
          </Link>
        </div>
      </div>

      {/* Bottom Section: Copyright */}
      <div className="border-t border-gray-700 mt-8 pt-4">
        <p className="text-center text-sm md:text-base">&copy; {new Date().getFullYear()} QuantumGulf. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
