import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Import Navigate
import Header from './components/header';
import Footer from './components/footer';
import Landing from './components/landing';
import Services from './pages/Services';
import Location from './pages/location';
import About from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Blog from './pages/blog';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop
import MobileAppDevelopment from './pages/MobileAppDevelopment';
import WebEnterprise from './pages/WebEnterprise';
import AIAnalyticsPage from './pages/Ai';
import UXDesignStudio from './pages/UxDesign';
import IoTAndSmartCities from './pages/Iot';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* This ensures that the page scrolls to the top on every route change */}
      <Header />
      <Routes>
        {/* Redirect any non-matching paths or page reloads to the landing page */}
        <Route path="/" element={<Landing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/location" element={<Location />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/mobile-app-development" element={<MobileAppDevelopment />} />
        <Route path="/web-development" element={<WebEnterprise />} />
        <Route path="/ai-analytics" element={<AIAnalyticsPage />} />
        <Route path="/ux-design" element={<UXDesignStudio />} />
        <Route path="/iot-smart-cities" element={<IoTAndSmartCities />} />

        {/* Redirect to Landing page on reload */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
