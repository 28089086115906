import React from 'react';
import { Link } from 'react-router-dom';
import { FaApple, FaAndroid, FaMobileAlt } from 'react-icons/fa';
import { CogIcon } from '@heroicons/react/24/solid';

const MobileAppDevelopment = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="relative h-screen bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1556740749-887f6717d7e4?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&ixid=MXwyMDg4Mzd8MHwxfGFsbHwxfHx8fHx8fHwxNjExNTMxMzI5&ixlib=rb-1.2.1&q=80&w=1080)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-600 opacity-60"></div>
        <div className="relative z-10 text-white text-center flex flex-col justify-center items-center h-full backdrop-blur-md">
          
            <h1 className="text-6xl font-bold mb-4 animate-zoomIn">Mobile App Development</h1>
            <p className="text-xl mb-6 max-w-2xl mx-auto">
              Crafting stunning mobile apps that elevate your brand’s digital experience.
            </p>
            <Link to="/contact" className="bg-indigo-600 px-8 py-3 rounded-full text-white hover:bg-indigo-700 transition duration-300 shadow-lg">
              Get in Touch
            </Link>
          
        </div>
      </header>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 px-4">
          
          <div className="flex justify-center items-center h-full">
            <CogIcon 
                className="text-indigo-600 transform hover:scale-105 transition-transform duration-500" 
                style={{ width: '200px', height: '200px' }} // Adjust the size as needed
            />
            </div>
         
          <div className="flex flex-col justify-center">
            <h2 className="text-4xl font-bold mb-6 text-gray-800">Why Choose Us?</h2>
            <div className="space-y-8">
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-indigo-600 text-white rounded-full shadow-lg">
                  <FaApple size={24} />
                </div>
                <p className="text-lg font-medium">Expertise in iOS and Android platforms</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-indigo-600 text-white rounded-full shadow-lg">
                  <FaMobileAlt size={24} />
                </div>
                <p className="text-lg font-medium">Custom app development tailored to your needs</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-indigo-600 text-white rounded-full shadow-lg">
                  <FaAndroid size={24} />
                </div>
                <p className="text-lg font-medium">Focus on user experience and design</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-indigo-600 text-white rounded-full shadow-lg">
                  <FaApple size={24} />
                </div>
                <p className="text-lg font-medium">Agile development for fast delivery</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="py-20 bg-white">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold text-gray-800">Our Expertise</h2>
          <p className="text-lg text-gray-500 mt-4">We excel in providing top-notch mobile solutions across platforms.</p>
        </div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-4">
          <div className="p-12 bg-gradient-to-b from-indigo-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaApple size={60} className="text-indigo-600 mb-6" />
            <h3 className="text-2xl font-semibold">iOS Development</h3>
            <p className="text-gray-600 mt-4">We create sleek, feature-rich apps designed for Apple’s ecosystem.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-green-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaAndroid size={60} className="text-green-600 mb-6" />
            <h3 className="text-2xl font-semibold">Android Development</h3>
            <p className="text-gray-600 mt-4">We build robust Android apps that reach millions of users.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-blue-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaMobileAlt size={60} className="text-blue-600 mb-6" />
            <h3 className="text-2xl font-semibold">Cross-Platform Solutions</h3>
            <p className="text-gray-600 mt-4">Our cross-platform apps run seamlessly on all devices.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <footer className="bg-indigo-600 py-16 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Let’s Build Your Dream App</h2>
        <p className="text-lg mb-8">Get in touch with us today and let’s discuss your project.</p>
        <Link to="/contact" className="bg-white text-indigo-600 px-8 py-3 rounded-full hover:bg-gray-100 transition duration-300 shadow-lg">
          Contact Us
        </Link>
      </footer>
    </div>
  );
};

export default MobileAppDevelopment;
