import React from 'react';
import officeIcon from '../assets/officeicon.png'; // Adjust the path according to your folder structure

const Location = () => {
  return (
    <section className="py-16 bg-gray-800 text-white" id="location">
      <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start justify-between">
        <div className="w-full md:w-auto text-center md:text-left ml-4">
          <div className="flex justify-center md:justify-start items-center mb-6"> {/* Adjust the order for icon on the right */}
            <h2 className="text-2xl font-extrabold" style={{ fontFamily: 'Montserrat, sans-serif' }}>
              Our Office
            </h2>
            <img 
              src={officeIcon} 
              alt="Office Icon" 
              className="w-12 h-12 ml-4 object-cover rounded-full" 
              style={{ flexShrink: 0 }} // Ensure the icon doesn't shrink
            />
          </div>
          <p className="text-lg leading-relaxed">
            Business Center, Sharjah Publishing City,<br />
            Sharjah, United Arab Emirates
          </p>
        </div>
      </div>
    </section>
  );
};

export default Location;
