import React from 'react';
import { Link } from 'react-router-dom';
import { FaCloud, FaCogs, FaLock } from 'react-icons/fa';

const WebEnterprise = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header
        className="relative h-screen bg-cover bg-center"
        style={{
            backgroundImage:
            'url(https://images.unsplash.com/photo-1517433456452-f9633a875f6f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDg4Mzd8MHwxfGFsbHwxfHx8fHx8fHwxNjExNTMxMzI5&ixlib=rb-1.2.1&q=80&w=1080)',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-gray-800 opacity-75"></div>
        <div className="relative z-10 text-white text-center flex flex-col justify-center items-center h-full">
          <h1 className="text-5xl font-bold mb-4 animate-fadeIn">
            Enterprise Web Development
          </h1>
          <p className="text-lg mb-6 max-w-xl mx-auto">
            Empower your business with scalable, secure, and modern web applications built to grow.
          </p>
          <Link
            to="/contact"
            className="bg-blue-600 px-6 py-3 rounded-full text-white hover:bg-blue-500 transition duration-300 shadow-lg"
          >
            Get in Touch
          </Link>
        </div>
      </header>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 px-4">
          <div className="flex justify-center">
          <img
              src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDg4Mzd8MHwxfGFsbHwxfHx8fHx8fHwxNjExNTMxMzI5&ixlib=rb-1.2.1&q=80&w=1080"
              alt="Enterprise Solutions"
              className="rounded-3xl shadow-xl transform hover:scale-105 transition-transform duration-500"
            />
          </div>
          <div className="flex flex-col justify-center space-y-6">
            <h2 className="text-4xl font-bold mb-6 text-gray-800">
              Why Choose Us?
            </h2>
            <div className="space-y-8">
              {[
                { icon: <FaCogs size={24} />, text: "Tailored web solutions built for your business" },
                { icon: <FaCloud size={24} />, text: "Cloud-based architectures for scalability" },
                { icon: <FaLock size={24} />, text: "Enterprise-grade security and performance" },
                { icon: <FaCogs size={24} />, text: "Seamless integration with your existing tools" },
              ].map((item, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <div className="p-4 bg-blue-600 text-white rounded-full shadow-lg">
                    {item.icon}
                  </div>
                  <p className="text-lg font-medium">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="py-20 bg-white">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold text-gray-800">Our Expertise</h2>
          <p className="text-lg text-gray-500 mt-4">
            From cloud-native solutions to secure web portals, we provide comprehensive enterprise solutions.
          </p>
        </div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-4">
          {[
            {
              icon: <FaCogs size={60} className="text-blue-600 mb-6" />,
              title: "Custom Web Solutions",
              desc: "We build custom, scalable web applications tailored to your enterprise needs.",
            },
            {
              icon: <FaCloud size={60} className="text-purple-600 mb-6" />,
              title: "Cloud-Native Apps",
              desc: "Leverage cloud technology for web apps that scale with your business.",
            },
            {
              icon: <FaLock size={60} className="text-green-600 mb-6" />,
              title: "Enterprise Security",
              desc: "We prioritize security with robust, enterprise-grade protection in every solution.",
            },
          ].map((expertise, index) => (
            <div
              key={index}
              className="p-12 bg-gradient-to-b from-blue-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500"
            >
              {expertise.icon}
              <h3 className="text-2xl font-semibold">{expertise.title}</h3>
              <p className="text-gray-600 mt-4">{expertise.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <footer className="bg-blue-600 py-16 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Build the Future of Your Business</h2>
        <p className="text-lg mb-8">
          Ready to take your enterprise to the next level? Let’s discuss your web app needs.
        </p>
        <Link
          to="/contact"
          className="bg-white text-blue-600 px-8 py-3 rounded-full hover:bg-gray-100 transition duration-300 shadow-lg"
        >
          Contact Us
        </Link>
      </footer>
    </div>
  );
};

export default WebEnterprise;
