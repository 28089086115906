import React from 'react';
import { Link } from 'react-router-dom';
import { FaPalette, FaPaintBrush, FaBezierCurve } from 'react-icons/fa';
import { PencilIcon } from '@heroicons/react/24/solid';

const UXDesignStudio = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="relative h-screen bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1557683316-973673baf926?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&ixid=MnwzNjUyOXwwfDF8fHx8fHx8fHwxNjExNTMxMzI5&ixlib=rb-1.2.1&q=80&w=1080)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-600 opacity-60"></div>
        <div className="relative z-10 text-white text-center flex flex-col justify-center items-center h-full backdrop-blur-md">
          <h1 className="text-6xl font-bold mb-4 animate-zoomIn">UX Design Studio</h1>
          <p className="text-xl mb-6 max-w-2xl mx-auto">
            Elevating user experiences with thoughtful, user-centered designs that captivate and engage.
          </p>
          <Link to="/contact" className="bg-pink-600 px-8 py-3 rounded-full text-white hover:bg-pink-700 transition duration-300 shadow-lg">
            Get in Touch
          </Link>
        </div>
      </header>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 px-4">
          
          <div className="flex justify-center items-center h-full">
            <PencilIcon 
              className="text-pink-600 transform hover:scale-105 transition-transform duration-500" 
              style={{ width: '200px', height: '200px' }} // Adjust size as needed
            />
          </div>
         
          <div className="flex flex-col justify-center">
            <h2 className="text-4xl font-bold mb-6 text-gray-800">Why Choose Us?</h2>
            <div className="space-y-8">
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-pink-600 text-white rounded-full shadow-lg">
                  <FaPalette size={24} />
                </div>
                <p className="text-lg font-medium">Creative and Custom UX Designs</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-pink-600 text-white rounded-full shadow-lg">
                  <FaPaintBrush size={24} />
                </div>
                <p className="text-lg font-medium">Pixel-Perfect UI/UX Implementation</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-pink-600 text-white rounded-full shadow-lg">
                  <FaBezierCurve size={24} />
                </div>
                <p className="text-lg font-medium">User-Centered Design Strategy</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-pink-600 text-white rounded-full shadow-lg">
                  <FaPalette size={24} />
                </div>
                <p className="text-lg font-medium">Agile and Iterative Design Process</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="py-20 bg-white">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold text-gray-800">Our Expertise</h2>
          <p className="text-lg text-gray-500 mt-4">We specialize in creating intuitive, seamless user experiences across platforms.</p>
        </div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-4">
          <div className="p-12 bg-gradient-to-b from-pink-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaPalette size={60} className="text-pink-600 mb-6" />
            <h3 className="text-2xl font-semibold">UI/UX Design</h3>
            <p className="text-gray-600 mt-4">Crafting intuitive interfaces that focus on the users journey.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-purple-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaPaintBrush size={60} className="text-purple-600 mb-6" />
            <h3 className="text-2xl font-semibold">Visual Design</h3>
            <p className="text-gray-600 mt-4">Creating visually stunning designs that align with your brands identity.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-blue-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaBezierCurve size={60} className="text-blue-600 mb-6" />
            <h3 className="text-2xl font-semibold">Interaction Design</h3>
            <p className="text-gray-600 mt-4">Designing interactive elements that enhance user engagement.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <footer className="bg-pink-600 py-16 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Let’s Design Your Next Big Idea</h2>
        <p className="text-lg mb-8">Get in touch with us today and let’s discuss your project.</p>
        <Link to="/contact" className="bg-white text-pink-600 px-8 py-3 rounded-full hover:bg-gray-100 transition duration-300 shadow-lg">
          Contact Us
        </Link>
      </footer>
    </div>
  );
};

export default UXDesignStudio;
