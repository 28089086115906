import React from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaBrain, FaRobot } from 'react-icons/fa';
import { CpuChipIcon } from '@heroicons/react/24/solid';

const AIAnalyticsPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="relative h-screen bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1518770660439-4636190af475?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&ixid=MnwzNjUyOXwwfDF8fHx8fHx8fHwxNjk3OTE3MDA&ixlib=rb-4.0.3&q=80&w=1920)' }}>
  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-600 opacity-60"></div>
        <div className="relative z-10 text-white text-center flex flex-col justify-center items-center h-full backdrop-blur-md">
          <h1 className="text-6xl font-bold mb-4">AI & Data Analytics</h1>
          <p className="text-xl mb-6 max-w-2xl mx-auto">
            Empowering your business with intelligent data insights and advanced AI technology.
          </p>
          <Link to="/contact" className="bg-blue-600 px-8 py-3 rounded-full text-white hover:bg-blue-700 transition duration-300 shadow-lg">
            Get in Touch
          </Link>
        </div>
      </header>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 px-4">
          <div className="flex justify-center">
          <div className="flex justify-center items-center h-full">
            <CpuChipIcon 
                className="text-indigo-600 transform hover:scale-105 transition-transform duration-500" 
                style={{ width: '200px', height: '200px' }} // Adjust the size as needed
            />
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-4xl font-bold mb-6 text-gray-800">Why Choose Our AI & Data Analytics Services?</h2>
            <div className="space-y-8">
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-blue-600 text-white rounded-full shadow-lg">
                  <FaChartLine size={24} />
                </div>
                <p className="text-lg font-medium">Data-Driven Insights for Smarter Decision-Making</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-blue-600 text-white rounded-full shadow-lg">
                  <FaBrain size={24} />
                </div>
                <p className="text-lg font-medium">Custom AI Solutions Tailored to Your Business</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-blue-600 text-white rounded-full shadow-lg">
                  <FaRobot size={24} />
                </div>
                <p className="text-lg font-medium">Automated Processes that Boost Efficiency</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="p-4 bg-blue-600 text-white rounded-full shadow-lg">
                  <FaChartLine size={24} />
                </div>
                <p className="text-lg font-medium">End-to-End Data Analytics Services</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="py-20 bg-white">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold text-gray-800">Our AI & Data Analytics Expertise</h2>
          <p className="text-lg text-gray-500 mt-4">Leveraging cutting-edge technology to deliver intelligent insights.</p>
        </div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-4">
          <div className="p-12 bg-gradient-to-b from-blue-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaChartLine size={60} className="text-blue-600 mb-6" />
            <h3 className="text-2xl font-semibold">Predictive Analytics</h3>
            <p className="text-gray-600 mt-4">Unlock future trends and data patterns to stay ahead of the curve.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-indigo-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaBrain size={60} className="text-indigo-600 mb-6" />
            <h3 className="text-2xl font-semibold">AI-Powered Solutions</h3>
            <p className="text-gray-600 mt-4">We develop advanced AI systems that deliver real-world results.</p>
          </div>
          <div className="p-12 bg-gradient-to-b from-purple-100 to-white rounded-3xl shadow-xl text-center transform hover:scale-105 transition-transform duration-500">
            <FaRobot size={60} className="text-purple-600 mb-6" />
            <h3 className="text-2xl font-semibold">Process Automation</h3>
            <p className="text-gray-600 mt-4">Streamline business operations with our tailored automation solutions.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <footer className="bg-blue-600 py-16 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Transform Your Business with AI</h2>
        <p className="text-lg mb-8">Get in touch with us today to discuss how we can elevate your business with AI and Data Analytics.</p>
        <Link to="/contact" className="bg-white text-blue-600 px-8 py-3 rounded-full hover:bg-gray-100 transition duration-300 shadow-lg">
          Contact Us
        </Link>
      </footer>
    </div>
  );
};

export default AIAnalyticsPage;
