import React from 'react';
import { FaMobileAlt, FaLaptopCode, FaBrain, FaPalette, FaCity } from 'react-icons/fa'; // Modern icons for services

const Services = () => {
  const services = [
    {
      title: 'Mobile App Development',
      description: 'Building innovative, high-performance mobile applications for iOS and Android platforms.',
      icon: <FaMobileAlt size={50} className="text-indigo-500" />
    },
    {
      title: 'Enterprise Web Development',
      description: 'Developing robust, scalable web applications tailored to your business needs.',
      icon: <FaLaptopCode size={50} className="text-indigo-500" />
    },
    {
      title: 'Artificial Intelligence & Data Analytics',
      description: 'AI-driven insights and data analytics to empower decision-making and enhance automation.',
      icon: <FaBrain size={50} className="text-indigo-500" />
    },
    {
      title: 'UX Design Studio',
      description: 'Designing intuitive and user-friendly interfaces that improve user experience and engagement.',
      icon: <FaPalette size={50} className="text-indigo-500" />
    },
    {
      title: 'IoT & Smart Cities',
      description: 'Integrating IoT solutions to create smarter cities and improve urban infrastructure.',
      icon: <FaCity size={50} className="text-indigo-500" />
    }
  ];

  return (
    <section className="py-20 bg-gray-100" id="services">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold text-gray-800 mb-12">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {services.map((service, index) => (
            <div key={index} className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
              <div className="mb-6 flex justify-center">
                {service.icon} {/* Modern icons for each service */}
              </div>
              <h3 className="text-2xl font-bold mb-4 text-gray-800">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
