import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faShoppingCart, faStethoscope, faGavel } from '@fortawesome/free-solid-svg-icons'; // Example FA icons

const industries = [
  
  { 
    name: 'Fintech & Insurtech', 
    slug: 'fintech', 
    icon: faBuildingColumns,
    description: 'Financial institutions can fully embrace cutting-edge technologies to go to the next level.', 
    services: ['Financial Apps', 'Metaverse Banking', 'Blockchain Technology', 'Hyper-personalized services'] 
  },
  { 
    name: 'Retail & E-commerce', 
    slug: 'retail', 
    icon: faShoppingCart,
    description: 'Retail businesses can thrive with our solutions.', 
    services: ['E-commerce Platforms', 'Personalized Shopping', 'Omnichannel Retail'] 
  },
  { 
    name: 'Healthcare', 
    slug: 'healthcare', 
    icon: faStethoscope,
    description: 'Empowering healthcare institutions with modern technology.', 
    services: ['Telemedicine', 'Patient Management', 'Health Analytics'] 
  },
  { 
    name: 'E-government', 
    slug: 'egovernment', 
    icon: faGavel,
    description: 'We help governments serve their citizens digitally.', 
    services: ['Digital Identity', 'E-services', 'Government Portals'] 

  },
  
  
];

const IndustriesPage = () => {
  // Set the default selected industry to the first industry (Fintech & Insurtech)
  const [selectedIndustry, setSelectedIndustry] = useState(0);

  const handleSelectIndustry = (index) => {
    setSelectedIndustry(selectedIndustry === index ? null : index);
  };
  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-white flex flex-col md:flex-row overflow-hidden">
      {/* Sidebar: List of Industries */}
      <div className="w-full md:w-1/4 bg-gray-900 flex flex-col justify-center items-center border-b md:border-b-0 md:border-r border-gray-700 p-6">
        <h1 className="text-3xl md:text-4xl font-extrabold mb-8 text-gray-200">Industries</h1>
        <ul className="space-y-8">
          {industries.map((industry, index) => (
            <li 
              key={index} 
              onClick={() => handleSelectIndustry(index)}
              className={`cursor-pointer py-6 px-8 rounded-lg flex flex-col items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-indigo-600 ${
                selectedIndustry === index ? 'bg-indigo-600 scale-105 shadow-xl' : 'bg-gray-800'
              }`}
            >
              <FontAwesomeIcon icon={industry.icon} className="text-4xl text-gray-200 mb-4 transition duration-300 ease-in-out transform hover:scale-110" />
              <span className="text-lg font-semibold text-gray-100">{industry.name}</span>

              {/* Display Details Under the Selected Industry in Mobile */}
              {selectedIndustry === index && (
                <div className="block md:hidden bg-white text-black p-4 mt-4 rounded-lg shadow-xl slide-down">
                  <div className="flex items-center mb-4">
                    <FontAwesomeIcon icon={industry.icon} className="text-4xl text-indigo-600 mr-4" />
                    <h2 className="text-2xl font-bold text-gray-800">{industry.name}</h2>
                  </div>
                  <p className="mb-4 text-sm font-light text-gray-700 leading-relaxed">{industry.description}</p>
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">Services We Provide:</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    {industry.services.map((service, serviceIndex) => (
                      <li key={serviceIndex}>{service}</li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Content: Expanded Industry Details for Desktop */}
      <div className="hidden md:flex w-3/4 p-12 items-center justify-center relative">
        {selectedIndustry !== null && (
          <div className="bg-white text-black p-6 md:p-10 rounded-xl shadow-2xl w-full max-w-3xl relative transition-all duration-500 ease-in-out transform hover:scale-105 slide-down">
            {/* Background Design Element */}
            <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-gray-300 rounded-xl opacity-10 z-0"></div>

            <div className="flex items-center mb-6 relative z-10">
              <FontAwesomeIcon icon={industries[selectedIndustry].icon} className="text-4xl text-indigo-600 mr-6" />
              <h2 className="text-4xl font-bold text-gray-800">{industries[selectedIndustry].name}</h2>
            </div>

            <p className="mb-6 text-lg font-light text-gray-700 relative z-10 leading-relaxed">{industries[selectedIndustry].description}</p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-4 relative z-10">Services We Provide:</h3>
            <ul className="list-disc list-inside space-y-2 text-gray-700 relative z-10">
              {industries[selectedIndustry].services.map((service, serviceIndex) => (
                <li key={serviceIndex}>{service}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndustriesPage;
